import styled from "styled-components"

export const SurveyContainer = styled.div`
    background: #FFFFFF;
    position relative;
    z-index: 1;
    margin-top: 100px;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const SurveyWrapper = styled.div`
    z-index: 1;
    min-height: 700px;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
    justify-content: center;
`

export const SurveyBtns = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
    }
`

export const BtnWrap = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width:200px;

    @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: none;
        margin-top: 16px;
    }
`

export const SurveyBtn = styled.button`
    border-radius: 10px;
    background: ${({primary}) => (primary ? '#191A23' : '#B8AEF2')};
    white-space: nowreap;
    padding: ${({big}) => (big ? '28px 20px' : '12px 10px')};
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-iems: center;
    tansition: 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        tansition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#B8AEF2' : '#191A23')};
        color: ${({dark}) => (dark ? 'white' : '#191A23')};
    }

    &:disabled {
        background: #E0E0E0; 
        color: #A0A0A0; 
        cursor: not-allowed;
    }
`

export const SurveyContent = styled.div`
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 50px;
    box-sizing: border-box;
    background: #F9F9F9;
    border-radius: 20px;
    color: #2A2933;
    line-height: 2.5rem;
`

export const SurveySheetH1 = styled.h1`
    margin-bottom: 30px;
`

export const SurveyQuestion = styled.label`
    font-weight: bold;
`

export const SurveyChecks = styled.div`
    margin-bottom: 30px;
`

export const SurveyAnswer = styled.div`
    display: flex; 
    gap: 20px;
`

export const SurveyForm = styled.form`
    max-width: 1000px; 
    width: 80%;
    margin: 0 auto;
    display: flex; 
    flex-direction: column; 
    gap: 10px;
    color: black;
    text-size: 2rem;
    line-height: 2.5rem;
`

export const SurveyInput = styled.input`
    width: 100%; 
    border-radius: 10px;
    line-height: 1.5rem;
    padding: 5px 20px;
`