import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBp-2cAttlNbNpdgISRuHO3aQ8BSwI0N3s",
    authDomain: "mysta-website.firebaseapp.com",
    projectId: "mysta-website",
    storageBucket: "mysta-website.appspot.com",
    messagingSenderId: "239497474374",
    appId: "1:239497474374:web:5e7c3fdb5f4abe1e0ef547"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db, collection, addDoc }