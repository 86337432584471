import React from 'react'
import { MCard, MCardContent, MCardH1, MCardH1Wrap, MCardH5, MCardImg, MCardP } from './MissionElements'

const MissionCard = ({bgColor, titleBg, title, sub_title, text, textColor, img}) => {
  return (
    <MCard bgColor={bgColor}>
        <MCardImg src={img} alt='card'/>
        <MCardContent>
            <MCardH1Wrap>
                <MCardH1 titleBg={titleBg}>{title}</MCardH1>
            </MCardH1Wrap>
            <MCardH5 textColor={textColor}>{sub_title}</MCardH5>
            <MCardP textColor={textColor}>{text}</MCardP>
        </MCardContent>
    </MCard>
  )
}

export default MissionCard
