import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="features" onClick={toggle}>Features</SidebarLink>
                <SidebarLink to="about" onClick={toggle}>About Us</SidebarLink>
                <SidebarLink to="contact" onClick={toggle}>Contact Us</SidebarLink>
                <SidebarLink to="access" onClick={toggle}>Access Now</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
