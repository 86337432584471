import React from 'react'
import { SurveyAnswer, SurveyChecks, SurveyContent, SurveyQuestion, SurveySheetH1 } from './SurveyElements'

const SurveySheet = ({ data, handleChange }) => {
    return (
        <SurveyContent>
            <SurveySheetH1>{data.sheetTitle}</SurveySheetH1>
            {data.questions.map((q) => (
                <div className="form-group" key={q.id}>
                    <SurveyQuestion>{q.question}</SurveyQuestion>
                    <SurveyChecks>
                        {q.answers.map((answer, index) => (
                            <SurveyAnswer className="form-check" key={index}>
                                <input
                                    className="form-check-input"
                                    type={q.multiChoice ? "checkbox" : "radio"}
                                    name={`question-${q.id}`}
                                    id={`question-${q.id}-answer-${index}`}
                                    value={answer}
                                    checked={q.multiChoice ? q.selectedAnswer.includes(answer) : q.selectedAnswer[0] === answer}
                                    onChange={(e) => handleChange(q.id, e.target.value, q.multiChoice)}
                                    style={{cursor: 'pointer'}}
                                />
                                <label className="form-check-label" htmlFor={`question-${q.id}-answer-${index}`} style={{cursor: 'pointer'}}>
                                    {answer}
                                </label>
                            </SurveyAnswer>
                        ))}
                    </SurveyChecks>
                </div>
            ))}
        </SurveyContent>
    )
}

export default SurveySheet