import { useTranslation } from 'react-i18next'

export const useCards = () => {
    const {t} = useTranslation()
    const cards = [
        {   
            bgColor: '#F9F9F9',
            titleBg: '#B8AEF2',
            title: t("mission.left_column.title"),
            sub_title: t("mission.left_column.sub_title"),
            text: t("mission.left_column.text"),
            textColor: 'black',
            img: require('../../images/vision.png')
        },
        {
            bgColor: '#F9F9F9',
            titleBg: '#B8AEF2',
            title: t("mission.right_column.title"),
            sub_title: t("mission.right_column.sub_title"),
            text: t("mission.right_column.text"),
            textColor: 'black',
            img: require('../../images/mission.png')
        }]

        return cards
    }
    