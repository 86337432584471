import React from 'react'
import { FCard, FCardH1, FCardH1Wrap, FCardImg, FCardP, FCardContent } from './FeaturesElements'

const FeatureCard = ({bgColor, titleBg, title, text, textColor, img}) => {
  return (
    <FCard bgColor={bgColor}>
        <FCardContent>
            <FCardH1Wrap>
                <FCardH1 titleBg={titleBg}>{title}</FCardH1>
            </FCardH1Wrap>
            <FCardImg src={img} alt='feature'/>
        </FCardContent>
        <FCardP textColor={textColor}>{text}</FCardP>
    </FCard>
  )
}

export default FeatureCard
