import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Services from '../components/ServicesSection'
import Footer from '../components/Footer'
import FeaturesSection from '../components/FeaturesSection'
import AboutSection from '../components/AboutSection'
import ContactSection from '../components/ContactSection'
import MissionSection from '../components/MissionSection'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Sidebar isOpen = {isOpen} toggle={toggle}/>
      <Navbar toggle={toggle} survey={false}/>
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <MissionSection />
      <Services/>
      <ContactSection/>
      <Footer/>
    </>
  )
}

export default Home
