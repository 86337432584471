import styled from "styled-components"

export const ContactContainer = styled.div`
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: white;
    margin-top: 30px;
    margin-bottom: 50px;

    @media screen and (max-width: 768px){
        height: 1100px;
    }

    @media screen and (max-width: 480px){
        height: 1300px;
    }
`

export const ContactWrapper = styled.div`
    display: flex;
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    padding: 0 50px;
    justify-content: center;
`
export const H1Wrap = styled.div`
    display: flex;
`

export const PWrap = styled.p`
    font-size: 1.1rem;
    text-align: left;
    color: black;
    flex: 1;
    padding-left: 10px;
    padding-top: 10px;
`

export const ContactH1 = styled.h1`
    font-size: 2.5rem;
    color: black;
    border-radius: 10px;
    margin-bottom: 32px;
    display: flex;
    font-weight: normal;
    background: #B8AEF2;
    padding: 6px 10px 0px 10px; 

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
`

export const ParentContainer = styled.div`
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
    justify-content: center;
`

export const ContactContent = styled.div`
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    box-sizing: border-box;
    background: #F9F9F9;
    border-radius: 20px;
    color: white;
    line-height: 1.5rem;
`

export const ContactRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 768px){
        grid-template-areas: 'col1 col1' 'col2 col2';
    }
`

export const ContactUsForm = styled.form`
    max-width: 600px; 
    margin: 0 auto;
    display: flex; 
    flex-direction: column; 
    gap: 10px;
    color: black;
    text-size: 2rem;
    line-height: 2.5rem;
`

export const Choices = styled.div`
    display: flex; 
    align-items: left; 
    gap: 20px;
`

export const ChoicesLabel = styled.label`
    display: flex; 
    align-items: center; 
    gap: 5px;
    cursor: pointer;
`

export const ContactInput = styled.input`
    width: 100%; 
    border-radius: 10px;
    line-height: 2.5rem;
    padding: 5px 20px;
`

export const ContactTextArea = styled.textarea`
    width: 100%; 
    border-radius: 10px;
    line-height: 2.5rem;
    padding: 5px 20px;
`

export const ContactBtn = styled.button`
    border-radius: 20px;
    background: ${({primary}) => (primary ? '#191A23' : '#B8AEF2')};
    white-space: nowreap;
    padding: ${({big}) => (big ? '28px 20px' : '12px 30px')};
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-iems: center;
    tansition: 0.2s ease-in-out;

    &:hover {
        tansition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#B8AEF2' : '#191A23')};
        color: ${({dark}) => (dark ? 'white' : '#191A23')};
    }
`