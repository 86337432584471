import React, { useState } from 'react'
import { BtnWrap, SurveyBtn, SurveyBtns, SurveyContainer, SurveyWrapper } from './SurveyElements'
import { useSurveyData } from './SurveyData'
import SurveySheet from './SurveySheet'
import { animateScroll as scroll } from 'react-scroll'
import SurveyValidate from './SurveyValidate'

const SurveySection = () => {
    const [currentSheetIndex, setCurrentSheetIndex] = useState(0)
    const surveyData = useSurveyData()
    const [data, setData] = useState(surveyData)
    const [showValidation, setShowValidation] = useState(false)
    const toggleHome = () => {
        scroll.scrollToTop()
    }

    const handleNext = () => {
        if (currentSheetIndex < data.length - 1) {
            setCurrentSheetIndex(currentSheetIndex + 1)
        } else {
            setShowValidation(true)
        }
        toggleHome()
    }

    const handleBack = () => {
        if (currentSheetIndex > 0) {
            setCurrentSheetIndex(currentSheetIndex - 1)
            toggleHome()
        }
    }

    const handleChange = (id, value, isMultiChoice) => {
        const updatedData = data.map(sheet => ({
            ...sheet,
            questions: sheet.questions.map(q => {
                if (q.id === id) {
                    if (isMultiChoice) {
                        const selectedAnswers = q.selectedAnswer.includes(value)
                            ? q.selectedAnswer.filter(answer => answer !== value)
                            : [...q.selectedAnswer, value];
                        return { ...q, selectedAnswer: selectedAnswers }
                    } else {
                        const selectedAnswers = [value]
                        return { ...q, selectedAnswer: selectedAnswers }
                    }
                }
                return q
            })
        }))
        setData(updatedData)
    }
  return (
    <SurveyContainer>
        <SurveyWrapper>
                {!showValidation ? (
                    <>
                        <SurveySheet data={data[currentSheetIndex]} handleChange={handleChange} />
                        <SurveyBtns>
                            <BtnWrap>
                                <SurveyBtn 
                                    onClick={handleBack} 
                                    disabled={currentSheetIndex === 0}
                                    primary = {true}
                                    dark = {false}
                                    big = {false}
                                >
                                    Back
                                </SurveyBtn>
                            </BtnWrap>
                            <BtnWrap>
                                <SurveyBtn
                                    onClick={handleNext} 
                                    primary = {true}
                                    dark = {false}
                                    big = {false}
                                >
                                    {currentSheetIndex === data.length - 1 ? "Validate": "Next"}
                                </SurveyBtn>
                            </BtnWrap>
                        </SurveyBtns>
                    </>
                ) : (
                    <SurveyValidate surveyData={data} />
                )}
                
        </SurveyWrapper>
    </SurveyContainer>
  )
}

export default SurveySection
