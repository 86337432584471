import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const HeroContainer = styled.div`
    background: #FFFFFF;
    min-height: 800px;
    position relative;
    z-index: 1;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const HeroWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const HeroRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        grid-template-areas: 'col1' 'col2';
    }
`

export const HeroH1 = styled.h1`
    color: #191A23;
    font-size: 64px;
    text-align: left;
    font-weight: normal;

    @media screen and (max-width: 768px){
        font-size: 52px;
    }

    @media screen and (max-width: 480px){
        font-size: 40px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #191A23;
    font-size: 24px;
    text-align: left;
    max-width: 600px;

    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width:200px;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRidht = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`


export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`