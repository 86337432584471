import { useTranslation } from "react-i18next"

export const useSurveyData = () => {
    const {t} = useTranslation()
    const surveyData = [
        {   
            sheetTitle: t("survey_data.sheets.1.sheet_title"),
            questions: [
                { id: 1, question: t("survey_data.sheets.1.questions.1.question"), answers: [t("survey_data.sheets.1.questions.1.answers.1"), t("survey_data.sheets.1.questions.1.answers.2"), t("survey_data.sheets.1.questions.1.answers.3")], selectedAnswer: "", multiChoice: false },
                { id: 2, question: t("survey_data.sheets.1.questions.2.question"), answers: [t("survey_data.sheets.1.questions.2.answers.1"), t("survey_data.sheets.1.questions.2.answers.2"), t("survey_data.sheets.1.questions.2.answers.3"), t("survey_data.sheets.1.questions.2.answers.4")], selectedAnswer: "", multiChoice: false },
                { id: 3, question: t("survey_data.sheets.1.questions.3.question"), answers: [t("survey_data.sheets.1.questions.3.answers.1"), t("survey_data.sheets.1.questions.3.answers.2"), t("survey_data.sheets.1.questions.3.answers.3")], selectedAnswer: "", multiChoice: false },
                { id: 4, question: t("survey_data.sheets.1.questions.4.question"), answers: [t("survey_data.sheets.1.questions.4.answers.1"), t("survey_data.sheets.1.questions.4.answers.2"), t("survey_data.sheets.1.questions.4.answers.3"), t("survey_data.sheets.1.questions.4.answers.4"), t("survey_data.sheets.1.questions.4.answers.5")], selectedAnswer: "", multiChoice: false }
            ]
        },
        {
            sheetTitle: t("survey_data.sheets.2.sheet_title"),
            questions: [
                { id: 5, question: t("survey_data.sheets.2.questions.1.question"), answers: [t("survey_data.sheets.2.questions.1.answers.1"), t("survey_data.sheets.2.questions.1.answers.2")], selectedAnswer: "", multiChoice: false },
                { id: 6, question: t("survey_data.sheets.2.questions.2.question"), answers: [t("survey_data.sheets.2.questions.2.answers.1"), t("survey_data.sheets.2.questions.2.answers.2")], selectedAnswer: "", multiChoice: false },
                { id: 7, question: t("survey_data.sheets.2.questions.3.question"), answers: [t("survey_data.sheets.2.questions.3.answers.1"), t("survey_data.sheets.2.questions.3.answers.2"), t("survey_data.sheets.2.questions.3.answers.3"), t("survey_data.sheets.2.questions.3.answers.4"), t("survey_data.sheets.2.questions.3.answers.5")], selectedAnswer: "", multiChoice: true }
            ]
        },
        {
            sheetTitle: t("survey_data.sheets.3.sheet_title"),
            questions: [
                { id: 8, question: t("survey_data.sheets.3.questions.1.question"), answers: [t("survey_data.sheets.3.questions.1.answers.1"), t("survey_data.sheets.3.questions.1.answers.2"), t("survey_data.sheets.3.questions.1.answers.3"), t("survey_data.sheets.3.questions.1.answers.4"), t("survey_data.sheets.3.questions.1.answers.5"), t("survey_data.sheets.3.questions.1.answers.6")], selectedAnswer: "", multiChoice: true },
                { id: 9, question: t("survey_data.sheets.3.questions.2.question"), answers: [t("survey_data.sheets.3.questions.2.answers.1"), t("survey_data.sheets.3.questions.2.answers.2"), t("survey_data.sheets.3.questions.2.answers.3"), t("survey_data.sheets.3.questions.2.answers.4"), t("survey_data.sheets.3.questions.2.answers.5")], selectedAnswer: "", multiChoice: false },
                { id: 10, question: t("survey_data.sheets.3.questions.3.question"), answers: [t("survey_data.sheets.3.questions.3.answers.1"), t("survey_data.sheets.3.questions.3.answers.2"), t("survey_data.sheets.3.questions.3.answers.3"), t("survey_data.sheets.3.questions.3.answers.4")], selectedAnswer: "", multiChoice: true },
                { id: 11, question: t("survey_data.sheets.3.questions.4.question"), answers: [t("survey_data.sheets.3.questions.4.answers.1"), t("survey_data.sheets.3.questions.4.answers.2"), t("survey_data.sheets.3.questions.4.answers.3"), t("survey_data.sheets.3.questions.4.answers.4"), t("survey_data.sheets.3.questions.4.answers.5")], selectedAnswer: "", multiChoice: false },
                { id: 12, question: t("survey_data.sheets.3.questions.5.question"), answers: [t("survey_data.sheets.3.questions.5.answers.1"), t("survey_data.sheets.3.questions.5.answers.2"), t("survey_data.sheets.3.questions.5.answers.3"), t("survey_data.sheets.3.questions.5.answers.4"), t("survey_data.sheets.3.questions.5.answers.5")], selectedAnswer: "", multiChoice: false },
                { id: 13, question: t("survey_data.sheets.3.questions.6.question"), answers: [t("survey_data.sheets.3.questions.6.answers.1"), t("survey_data.sheets.3.questions.6.answers.2"), t("survey_data.sheets.3.questions.6.answers.3"), t("survey_data.sheets.3.questions.6.answers.4")], selectedAnswer: "", multiChoice: true },
                { id: 14, question: t("survey_data.sheets.3.questions.7.question"), answers: [t("survey_data.sheets.3.questions.7.answers.1"), t("survey_data.sheets.3.questions.7.answers.2"), t("survey_data.sheets.3.questions.7.answers.3"), t("survey_data.sheets.3.questions.7.answers.4"), t("survey_data.sheets.3.questions.7.answers.5")], selectedAnswer: "", multiChoice: false },
                { id: 15, question: t("survey_data.sheets.3.questions.8.question"), answers: [t("survey_data.sheets.3.questions.8.answers.1"), t("survey_data.sheets.3.questions.8.answers.2"), t("survey_data.sheets.3.questions.8.answers.3"), t("survey_data.sheets.3.questions.8.answers.4")], selectedAnswer: "", multiChoice: false },
                { id: 16, question: t("survey_data.sheets.3.questions.9.question"), answers: [t("survey_data.sheets.3.questions.9.answers.1"), t("survey_data.sheets.3.questions.9.answers.2")], selectedAnswer: "", multiChoice: false }
            ]
        },
        {
            sheetTitle: t("survey_data.sheets.4.sheet_title"),
            questions: [
                { id: 17, question: t("survey_data.sheets.4.questions.1.question"), answers: [t("survey_data.sheets.4.questions.1.answers.1"), t("survey_data.sheets.4.questions.1.answers.2"), t("survey_data.sheets.4.questions.1.answers.3")], selectedAnswer: "", multiChoice: false },
                { id: 18, question: t("survey_data.sheets.4.questions.2.question"), answers: [t("survey_data.sheets.4.questions.2.answers.1"), t("survey_data.sheets.4.questions.2.answers.2"), t("survey_data.sheets.4.questions.2.answers.3"), t("survey_data.sheets.4.questions.2.answers.4")], selectedAnswer: "", multiChoice: true },
                { id: 19, question: t("survey_data.sheets.4.questions.3.question"), answers: [t("survey_data.sheets.4.questions.3.answers.1"), t("survey_data.sheets.4.questions.3.answers.2"), t("survey_data.sheets.4.questions.3.answers.3"), t("survey_data.sheets.4.questions.3.answers.4"), t("survey_data.sheets.4.questions.3.answers.5")], selectedAnswer: "", multiChoice: true },
            ]
        }
    ]

    return surveyData
}