import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    background: ${({scrollNav})=> (scrollNav ? '#F9F9F9': 'transparent')};
    height: 90px;
    margin-top: -90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 90px;
    z-index: 1;
    width: 100%;
    padding 0 24px;
    max-width: 1100px;
`

export const NavLogo = styled(LinkR)`
    color: #191A23;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    max-width: 120px;
`

export const AppLogo = styled.img`
    width: 100%;
    margin: 10px 0 10px 0;
    padding-right: 0;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        color: #191A23;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`

export const NavMenu = styled.ul`
    display: ${({survey}) => (survey ? 'none' : 'flex')};
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const NavItem = styled.li`
    height: 80px;
    margin-top: 10px
`

export const NavLinks = styled(LinkS)`
    color: #191A23;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 2rem;
    height: 100%;
    cursor: pointer;
    ${({main})=> (main ? 'border: 1px solid #191A23': '')};
    ${({main})=> (main ? 'border-radius: 10px': '')};
    

    &.active {
        border-bottom: 3px solid #B8AEF2;
    }
    
    &:hover {
        border-bottom: 3px solid #B8AEF2;
        ${({main})=> (main ? 'border: 1px solid #B8AEF2': '')};
        ${({main})=> (main ? 'background-color: #B8AEF2': '')};
    }
`
