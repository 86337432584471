import React, { useState } from 'react'
import { ButtonR, Button } from '../ButtonElement'
import { 
    ServicesContainer,  
    ServicesP, 
    ServicesWrapper,
    ServicesH1Title,
    ParentContainer,
    ServicesBtnWrapper,
    ServicesBtns } from './ServicesElements'
import { ArrowForward, ArrowRidht, ImgWrap, Img, HeroRow, Column1, Column2 } from '../HeroSection/HeroElements'
import { useTranslation } from 'react-i18next'

const Services = () => {
    const {t} = useTranslation()
    const [hoverSurvey, setHoverSurvey] = useState(false)
    const [hoverDemo, setHoverDemo] = useState(false)
    const [hoverNews, setHoverNews] = useState(false)
    const accessImg = require('../../images/access-img.png')

    const onHoverSurvey = () => {
        setHoverSurvey(!hoverSurvey)
    }
    const onHoverDemo = () => {
        setHoverDemo(!hoverDemo)
    }
    const onHoverNews = () => {
        setHoverNews(!hoverNews)
    }
  return (
    <ServicesContainer id="access">
        <ServicesWrapper>
            <ParentContainer>
                <HeroRow>
                    <Column1>
                        <ServicesH1Title>{t("access.access_header")}</ServicesH1Title>
                        <ServicesP>{t("access.access_p")}</ServicesP>
                        <ServicesBtns>
                            <ServicesBtnWrapper>
                                <ButtonR to='/survey' 
                                        onMouseEnter={onHoverSurvey}
                                        onMouseLeave={onHoverSurvey}
                                        primary = {true}
                                        dark = {false}
                                        big = {false}>
                                    {t("access.access_links.access_link1")} {hoverSurvey ? <ArrowForward/> : <ArrowRidht/>}
                                </ButtonR>
                            </ServicesBtnWrapper>
                            <ServicesBtnWrapper>
                                <Button to='contact' 
                                        onMouseEnter={onHoverDemo}
                                        onMouseLeave={onHoverDemo}
                                        primary = {true}
                                        dark = {false}
                                        big = {false}>
                                    {t("access.access_links.access_link2")} {hoverDemo ? <ArrowForward/> : <ArrowRidht/>}
                                </Button>
                            </ServicesBtnWrapper> 
                            <ServicesBtnWrapper>
                                <Button to='footer' 
                                        onMouseEnter={onHoverNews}
                                        onMouseLeave={onHoverNews}
                                        primary = {true}
                                        dark = {false}
                                        big = {false}>
                                    {t("access.access_links.access_link3")} {hoverNews ? <ArrowForward/> : <ArrowRidht/>}
                                </Button>
                            </ServicesBtnWrapper>
                        </ServicesBtns>    
                    </Column1>
                    <Column2>
                        <ImgWrap>
                        <Img src={accessImg} alt="Mystra"/>
                        </ImgWrap>
                    </Column2>
                </HeroRow>
            </ParentContainer>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
