import { useTranslation } from 'react-i18next'

export const useFeatures = () => {
    const {t} = useTranslation()
    const features = [
        {   
            bgColor: '#B8AEF2',
            titleBg: 'white',
            title: t("features.cards.1.title"),
            text: t("features.cards.1.subtitle"),
            textColor: 'black',
            img: require('../../images/feature-1.png')
        },
        {
            bgColor: '#191A23',
            titleBg: '#B8AEF2',
            title: t("features.cards.2.title"),
            text: t("features.cards.2.subtitle"),
            textColor: 'white',
            img: require('../../images/feature-2.png')
        },
        {
            bgColor: '#F9F9F9',
            titleBg: '#B8AEF2',
            title: t("features.cards.3.title"),
            text: t("features.cards.3.subtitle"),
            textColor: 'black',
            img: require('../../images/feature-3.png')
        },
        {
            bgColor: '#B8AEF2',
            titleBg: 'white',
            title: t("features.cards.4.title"),
            text: t("features.cards.4.subtitle"),
            textColor: 'black',
            img: require('../../images/feature-4.png')
        }
    ]

    return features
}
