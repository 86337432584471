import React, { useState } from 'react'
import { ArrowForward, 
         ArrowRidht, 
         HeroRow,
         Column1,
         Column2, 
         HeroBtnWrapper, 
         HeroContainer, 
         HeroWrapper, 
         HeroH1, 
         HeroP,
         ImgWrap,
         Img} from './HeroElements'
import { Button } from '../ButtonElement'
import { useTranslation } from 'react-i18next'

const HeroSection = () => {
    const {t} = useTranslation()
    const [hover, setHover] = useState(false)
    const heroImg = require('../../images/hero-img.png')

    const onHover = () => {
        setHover(!hover)
    }

  return (
    <HeroContainer>
      <HeroWrapper>
        <HeroRow>
          <Column1>
            <HeroH1>{t("home.title")}</HeroH1>
            <HeroP>
              {t("home.subtitle")}
            </HeroP>
            <HeroBtnWrapper>
              <Button to='contact' 
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                      primary = {true}
                      dark = {false}
                      big = {true}>
                  {t("home.home_link")} {hover ? <ArrowForward/> : <ArrowRidht/>}
              </Button>
            </HeroBtnWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={heroImg} alt="Mystra"/>
            </ImgWrap>
          </Column2>
        </HeroRow>
      </HeroWrapper>
    </HeroContainer>
  )
}

export default HeroSection
