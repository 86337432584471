import styled from "styled-components"

export const ServicesContainer = styled.div`
    background: #FFFFFF;
    position relative;
    z-index: 1;
    margin-top: 30px;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const ServicesH1Title = styled.h1`
    font-size: 2rem;
    color: black;
    margin-bottom: 32px;
    text-align: left;

    @media screen and (max-width: 480px){
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
`

export const ServicesP = styled.p`
    font-size: 1.1rem;
    text-align: left;
    color: black;
`

export const ServicesWrapper = styled.div`
    z-index: 1;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
    justify-content: center;
`

export const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F9F9F9;
    border-radius: 20px;
`


export const ServicesBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width:250px;

    @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: none;
        margin-top: 16px;
    }
`

export const ServicesBtns = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }
`