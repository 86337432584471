import React from 'react'
import { useTranslation } from 'react-i18next'
import { FooterOption, FooterSelect } from './FooterElements'

const LanguageSelector = () => {
    const {i18n} = useTranslation()

    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }

  return (
    <FooterSelect onChange={(e) => changeLanguage(e.target.value)} value={i18n.language}>
      <FooterOption value="en">English</FooterOption>
      <FooterOption value="fr">Français</FooterOption>
      <FooterOption value="ar">العربية</FooterOption>
    </FooterSelect>
  )
}

export default LanguageSelector
