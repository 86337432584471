import React from 'react'
import { Column1, Column2, Img, ImgWrap } from '../HeroSection/HeroElements'
import { ContactContainer, ContactContent, ContactH1, ContactRow, ContactWrapper, H1Wrap, ParentContainer, PWrap } from './ContactElements'
import ContactForm from './ContactForm'
import { useTranslation } from 'react-i18next'

const ContactSection = () => {
    const contactImg = require('../../images/contact-img.png')
    const {t} = useTranslation()
  return (
    <ContactContainer id="contact">
        <ContactWrapper>
            <H1Wrap>
                <ContactH1>{t("contact.title")}</ContactH1>
            </H1Wrap>
            <PWrap>{t("contact.subtitle")}</PWrap>
        </ContactWrapper>
        <ParentContainer>
            <ContactContent>
                <ContactRow>
                    <Column1>
                        <ContactForm />
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={contactImg} alt="Mystra"/>
                        </ImgWrap>
                    </Column2>
                </ContactRow>
            </ContactContent>
        </ParentContainer>
    </ContactContainer>
  )
}

export default ContactSection
