
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import Home from './pages'
import Survey from './pages/survey';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path='/' Component={Home} exact />
        <Route path='/survey' Component={Survey} exact />
      </Routes>
    </Router>
  );
}

export default App;
