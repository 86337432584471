import styled from "styled-components"
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from "react-router-dom"

export const Button = styled(LinkS)`
    border-radius: 10px;
    background: ${({primary}) => (primary ? '#191A23' : '#B8AEF2')};
    white-space: nowreap;
    padding: ${({big}) => (big ? '28px 20px' : '12px 10px')};
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-iems: center;
    tansition: 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        tansition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#B8AEF2' : '#191A23')};
        color: ${({dark}) => (dark ? 'white' : '#191A23')};
    }
`

export const ButtonR = styled(LinkR)`
    border-radius: 10px;
    background: ${({primary}) => (primary ? '#191A23' : '#B8AEF2')};
    white-space: nowreap;
    padding: ${({big}) => (big ? '28px 20px' : '12px 10px')};
    color: ${({dark}) => (dark ? 'black' : 'white')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-iems: center;
    tansition: 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        tansition: 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#B8AEF2' : '#191A23')};
        color: ${({dark}) => (dark ? 'white' : '#191A23')};
    }
`