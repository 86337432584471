import React from 'react'
import { useFeatures } from './Data'
import FeatureCard from './FeatureCard'
import { FeaturesContainer, FWrapper, H1Wrap, FeaturesH1, FeaturesWrapper } from './FeaturesElements'
import { useTranslation } from 'react-i18next'

const FeaturesSection = () => {
    const features = useFeatures()
    const {t} = useTranslation()
  return (
    <FeaturesContainer id="features">
        <FWrapper>
            <H1Wrap>
                <FeaturesH1>{t("features.title")}</FeaturesH1>
            </H1Wrap>
            <FeaturesWrapper>
                {features.map((feature, index) => (
                    <FeatureCard key={index} {...feature}/>
                ))}
            </FeaturesWrapper>
        </FWrapper>
    </FeaturesContainer>
  )
}

export default FeaturesSection
