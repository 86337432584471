import React from 'react'
import { useCards } from './Data'
import { CardsWrapper, MissionContainer, MissionWrapper } from './MissionElements'
import MissionCard from './MissionCard'

const MissionSection = () => {
    const cards = useCards()
  return (
    <MissionContainer id="mission">
        <MissionWrapper>
            <CardsWrapper>
                {cards.map((card, index) => (
                    <MissionCard key={index} {...card}/>
                ))}
            </CardsWrapper>
        </MissionWrapper>
    </MissionContainer>
  )
}

export default MissionSection
